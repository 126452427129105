.login-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    height: 100vh;
}

.login-page .login-page-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-page .login-page-pinbox {
    margin: 20px;
}

.button-wrapper {
    position: relative;
}

.button-progress {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -12px 0 0 -12px;
}