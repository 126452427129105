.add-recurring-dialog {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.add-recurring-dialog .day-label {
    margin-bottom: 20px;
}

.loading-wrapper .schedule-backdrop {
    z-index: 10;
}