.inspections-list {
    width: 100%;
    position: relative;
    overflow: auto;
    height: calc(100vh - 198px);
}

.inspection-list-item {
    width: 100%;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
}

.inspection-calendar-item {
    width: 100%;
    padding: 3px;
    text-decoration: none;
    display: block;
}

.inspection-calendar-item:hover {
    cursor: pointer;
}

.inspection-list-item:hover {
    cursor: pointer;
}

.inspection-list-section {
    list-style-position: inside;
    padding-left: 0;
}

.inspection-list-section-header {
    background-color: #fafafa;
}

.inspection-future {
    background-color: #757575;
}

.inspection-future:hover {
    background-color: #494949;
}

.inspection-completed {
    background-color: #81c784;
}

.inspection-completed:hover {
    background-color: #519657;
}

.inspection-incomplete-ok {
    background-color: #ffd54f;
}

.inspection-incomplete-ok:hover {
    background-color: #c8a415;
}

.inspection-incomplete {
    background-color: #ff8a65;
}

.inspection-incomplete:hover {
    background-color: #c75b39;
}

.inspection-halfcomplete {
    background: repeating-linear-gradient(
        45deg,
        #81c784,
        #81c784 10px,
        #ff8a65 10px,
        #ff8a65 20px
    )
}

.inspection-halfcomplete:hover {
    background: repeating-linear-gradient(
        45deg,
        #519657,
        #519657 10px,
        #c75b39 10px,
        #c75b39 20px
    )
}

.inspection-halfcomplete-ok {
    background: repeating-linear-gradient(
        45deg,
        #81c784,
        #81c784 10px,
        #ffd54f 10px,
        #ffd54f 20px
    )
}

.inspection-halfcomplete-ok:hover {
    background: repeating-linear-gradient(
        45deg,
        #519657,
        #519657 10px,
        #c8a415 10px,
        #c8a415 20px
    )
}

.inspections-list-fetchmore {
    display: flex;
    justify-content: center;
}