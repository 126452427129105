.question-card {
    margin: 20px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.question-right {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.question-actions-move {
    display: flex;
    flex-direction: column;
}

.question-editable {
    width: 100%;
}

.question-text {
    width: 100%;
    white-space: pre-line;
}

.question-shortname {
    white-space: nowrap;
    padding-right: 50px;
}

.questions-top {
    display: flex;
    flex-direction: row-reverse;
}

.questions-bottom {
    display: flex;
    flex-direction: row-reverse;
}
.questions-add-wrapper {
    position: relative;
}

.questions-add {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    margin: -34px 0 0 -34px;
}