.inspection-heading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.inspection-side-by-side-wrapper {
    display: flex;
    flex-direction: column;
}

.inspection-side-by-side {
    display: flex;
    width: 100%;
}

.inspection-wrapper {
    height: calc(100vh - 112px);
    overflow-y: auto;
}

.inspection-status-section {
    flex: 1;
    margin-top: 10px;
    margin-bottom: 10px;
}

.inspection-side-divider {
    margin-left: 10px;
    margin-right: 10px;
}

.inspection-label-nys {
    color: red;
}

.inspection-status-answer {
    padding: 15px;
}

.inspection-answer-good {
    color: green;
}

.inspection-answer-bad {
    color: red;
}

.inspection-summary {
    padding: 10px;
}

.inspection-filter-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
}

.inspections-calendar-wrapper {
    height: 80vh;
}